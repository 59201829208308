.mb10 { margin-bottom: 10px; }
.mb20 { margin-bottom: 20px; }
.mb30 { margin-bottom: 30px; }
.mb40 { margin-bottom: 40px; }
.mb50 { margin-bottom: 50px; }
.mb80 { margin-bottom: 80px; }
.mb100 { margin-bottom: 100px; }
.mt10 { margin-top: 10px; }
.mt20 { margin-top: 20px; }
.mt30 { margin-top: 30px; }
.mt40 { margin-top: 40px; }
.pl10{ padding-left: 10px; }
.pl20{ padding-left: 20px; }
.pl30{ padding-left: 30px; }
.pl40{ padding-left: 40px; }
.pl50{ padding-left: 50px; }
.pr10{ padding-right: 10px; }
.pr20{ padding-right: 20px; }
.pr30{ padding-right: 30px; }
.pr40{ padding-right: 40px; }
.pr50{ padding-right: 50px; }


@include media-breakpoint-down(sm){
  .img-sm-80{
    width: 80%;
    height:auto;
  }
  .img-sm-60{
    width: 60%;
    height:auto;
  }
  .text-sm-center{
    text-align: center !important;
  }
  .mb-sm-0{
    margin-bottom: 0 !important;
  }
  .d-sm-none{
    display: none;
  }
  .mb40-sm { margin-bottom: 40px; }
  .mb50-sm { margin-bottom: 50px; }
  .mb60-sm { margin-bottom: 60px; }
  .mb100-sm { margin-bottom: 100px !important; }

  .w-100-sm{
    max-width: inherit;
    width: 100%;
  }
}

// ボタン
.btn-white-arrow{
  position: relative;
  display: inline-block;
  z-index: 1;
  min-width: 210px;
  padding: 15px ;
  background-color: $color-white;
  font-family: $font-family-montserrat;
  font-size: $font-size-12;
  font-weight: 600;
  letter-spacing: 0.3em;
  text-align: center;
  margin: 47px 0;
  color: $color-bule-text;
  text-decoration: none;
  transition: .3s;
  &.btn-wide{
    padding: 15px 74px 15px 28px;
  }
  &:after{
    vertical-align: middle;
    display: inline-block;
    content: url(../img/icon_arrow_sin_r.png);
    position: absolute;
    right: 12px;
    margin-top: -2px;
  }
  &:visited{
    color: $color-bule-text;
  }
  &:hover{
    color: #fff;
    text-decoration: none;
  }
  &:hover:after{
    content: url(../img/icon_arrow_sin_white_r.png);
  }
  &:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    content: '';
    background: $color-btn-hover;
    transform-origin: right top;
    transform: scale(0, 1);
    transition: transform .3s;
  }
  &:hover:before{
    transform-origin: left top;
    transform: scale(1, 1);
  }

}
.btn-white-arrow-l{
  position: relative;
  display: inline-block;
  z-index: 1;
  min-width: 210px;
  padding: 15px ;
  background-color: $color-white;
  font-family: $font-family-montserrat;
  font-size: $font-size-12;
  font-weight: 600;
  letter-spacing: 0.3em;
  text-align: center;
  margin: 47px 0;
  color: $color-bule-text;
  text-decoration: none;
  transition: .3s;
  &.btn-wide{
    padding: 15px 28px 15px 38px;
  }
  &:before{
    vertical-align: middle;
    display: inline-block;
    content: url(../img/icon_arrow_sin_l.png);
    position: absolute;
    left: 12px;
    margin-top: -2px;
  }
  &:visited{
    color: $color-bule-text;
  }
  &:hover{
    color: #fff;
    text-decoration: none;
  }
  &:hover:before{
    content: url(../img/icon_arrow_sin_w_l.png);
  }
  &:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    content: '';
    background: $color-btn-hover;
    transform-origin: right top;
    transform: scale(0, 1);
    transition: transform .3s;
  }
  &:hover:after{
    transform-origin: left top;
    transform: scale(1, 1);
  }

}
.btn-inline-arrow{
  display: inline-block;
  position: relative;
  border-bottom: 1px solid $color-white;
  padding: 8px 52px 8px 0;
  font-size: $font-size-10;
  &:after{
    vertical-align: middle;
    display: inline-block;
    content: url(../img/icon_arrow_sin_w_r.png);
    position: absolute;
    right: 0px;
    margin-top: -2px;
  }
  &:visited{
    color: $color-white;
  }
  &:hover{
    text-decoration: none;
    opacity: 0.8;
  }
}
.btn-normal{
  position: relative;
  display: inline-block;
  z-index: 1;
  min-width: 210px;
  padding: 15px ;
  border: 1px solid $color-white;
  font-family: $font-family-montserrat;
  font-size: $font-size-12;
  font-weight: 600;
  letter-spacing: 0.3em;
  text-align: center;
  margin: 47px 0;
  color: $color-white;
  text-decoration: none;
  transition: .3s;
  &:visited{
    color: $color-white;
  }
  &:hover{
    color: $color-bule-text;
    text-decoration: none;
  }
  &:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    content: '';
    background: $color-white;
    transform-origin: right top;
    transform: scale(0, 1);
    transition: transform .3s;
  }
  &:hover:before{
    transform-origin: left top;
    transform: scale(1, 1);
  }

}
.btn-blue{
  position: relative;
  display: inline-block;
  z-index: 1;
  min-width: 210px;
  padding: 15px ;
  border: 1px solid $color-white;
  font-family: $font-family-montserrat;
  font-size: $font-size-12;
  font-weight: 600;
  letter-spacing: 0.3em;
  text-align: center;
  margin: 0;
  color: $color-bule-text;
  text-decoration: none;
  transition: .3s;
  background: $color-white;
  &:visited{
    color: $color-bule-text;
  }
  &:hover{
    color: $color-white;
    text-decoration: none;
  }
  &:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    content: '';
    background: $color-bule-text;
    transform-origin: right top;
    transform: scale(0, 1);
    transition: transform .3s;
  }
  &:hover:before{
    transform-origin: left top;
    transform: scale(1, 1);
  }

}
.btn-blue-arrow{
  position: relative;
  display: inline-block;
  z-index: 1;
  min-width: 210px;
  padding: 15px ;
  background-color: $color-btn-hover;
  font-family: $font-family-montserrat;
  font-size: $font-size-12;
  font-weight: 600;
  letter-spacing: 0.3em;
  text-align: center;
  margin: 47px 0;
  color: $color-white;
  text-decoration: none;
  transition: .3s;
  border: 1px solid $color-btn-hover;
  &.btn-wide{
    padding: 15px 74px 15px 28px;
  }
  &:after{
    vertical-align: middle;
    display: inline-block;
    content: url(../img/icon_arrow_sin_white_r.png);
    position: absolute;
    right: 12px;
    margin-top: -2px;
  }
  &:visited{
    color: $color-white;
  }
  &:hover{
    color: $color-btn-hover;
    text-decoration: none;
  }
  &:hover:after{
    content: url(../img/icon_arrow_sin_r.png);
  }
  &:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    content: '';
    background: $color-white;
    transform-origin: right top;
    transform: scale(0, 1);
    transition: transform .3s;
  }
  &:hover:before{
    transform-origin: left top;
    transform: scale(1, 1);
  }

}
// sm テキスト折りたたみ
.acoddion-text{
  &__sm{
    &__btn {
      display: none;
    }
    @include media-breakpoint-down(sm){
      &__body{
        display: none;
        overflow: hidden;
      }
      &__btn{
        display: block;
        font-family: $font-family-montserrat;
        font-size: $font-size-12;
        text-align: center;
        padding: 12px 0;
        border-bottom: 1px solid $color-white;
        background-image: url(../img/icon_arrow_down.png);
        background-position: 95% center;
        background-repeat: no-repeat;
        cursor: pointer;
        span:nth-child(1){
          display: inline;
        }
        span:nth-child(2){
          display: none;
        }
        &.open{
          background-image: url(../img/icon_arrow_up.png);
          span:nth-child(1){
            display: none;
          }
          span:nth-child(2){
            display: inline;
          }
        }
      }
    }
  }

}

//パネル
.panel-th{
  border: 1px solid $color-white;
  padding: 11px 11px 35px 11px;
  position: relative;
  a{
    display: block;
    &:hover{
      text-decoration: none;
    }
  }
  img{
    width: 100%;
    height: auto;
    margin-bottom: 22px;
    @include media-breakpoint-down(sm){
      margin-bottom: 8px;
    }
  }
  &__time{
    font-size: $font-size-14;
    margin-bottom:3px;
  }
  &__ttl{
    margin: 0 0 15px 0;
    font-size: $font-size-18;
  }
  &__text{
    font-size: $font-size-14;
    margin-bottom: 0;
  }
  &:after{
    position: absolute;
    bottom: 0;
    right: 0;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 30px 30px;
    border-color: transparent transparent $color-white transparent;
    transition: .3s;
  }
  &:hover:after{
    border-width: 0 0 60px 60px;
  }
}



.bx-wrapper{
  border: none;
  box-shadow: none;
  background: none;
  .bx-controls-direction{
    a{
      background-color: $color-white;
      width: 96px;
      height: 96px;
      border-radius: 48px;
      top: 50%;
      margin-top: -48px;
      box-shadow: 0px 0px 7px 8px rgba(0, 0, 0, 0.06);
      @include media-breakpoint-down(sm){
       display: none;
      }
    }
  }
  .bx-prev, .bx-next{

  }
  .bx-prev {
    background-image: url(../img/icon_arrow_l.png);
    background-position: 39px 37px;
    &:hover{
      background-position: 39px 37px;
    }
  }
  .bx-next {
    background-image: url(../img/icon_arrow_r.png);
    background-position: 44px 37px;
    &:hover{
      background-position: 44px 37px;
    }
  }
  .bx-pager{
    .bx-pager-item{
      .bx-pager-link{
          background: $color-white;
        &.active{
          background: none;
          border: 1px solid $color-white;
        }
      }
    }
  }
}


.fadeIn {
  opacity: 0;
  transition: 2s;
}
.fadeIn.is-show {
  opacity: 1;
}
.fadeIn_up {
  opacity: 0;
  transform: translate(0, 20%);
  transition: 2s;
}
.fadeIn_up.is-show {
  transform: translate(0, 0);
  opacity: 1;
}
.zoomIn_up {
  opacity: 0;
  transform: scale(0.1,0.1);
  transition: 2s;
}
.zoomIn_up.is-show {
  transform: scale(1,1);
  opacity: 1;
}

.img-frame-pc{
  padding: 10px;
  border: 1px solid $color-white;
  @include media-breakpoint-down(sm){
    padding: 0;
    border: none;
  }
}

.kai-img{
}
.kai-show{
  width: 0;
  transition: width 3s ease-in-out;
  -webkit-transition: width 3s ease-in-out;

}
.slider2  {
  //position: absolute;
  //width: 100%;
  //height: 100%;
}

.li-list{
  position: relative;
}
.slider2  .wrap {
  overflow: hidden;
  width: 0%;
  height: 100%;
}

.slider2 .img {
  //background: no-repeat center center;
  //background-size: cover;
  width: 100vw;
  height: 100%;
  overflow: hidden;
}

.slider2 .active .img {
  //transition: transform 0.8s cubic-bezier(0.39, 0.575, 0.565, 1);
  //-webkit-transition: transform 0.8s cubic-bezier(0.39, 0.575, 0.565, 1);
  //transform: translateX(0);
}

.slider2 .active .wrap {
  width: 100%;
  transition: width 2s ease;
  -webkit-transition: width 2s ease;
}
.slider2 .hide .wrap {
  width: 0;
  transition: width 2s ease;
  -webkit-transition: width 2s ease;
}
