@mixin clearfix {
  zoom: 1;
  &:before,
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}
@mixin pc {
  @media (max-width: ($pc)) {
    @content;
  }
}
@mixin tab {
  @media (max-width: ($tab)) {
    @content;
  }
}
@mixin sp {
  @media (max-width: ($sp)) {
    @content;
  }
}

// pxから%を計算して返す
@function getPercent($target-px, $wrap-px) {
  @return $target-px / $wrap-px * 100%;
}
// pxから%(VW)を計算して返す
@function getVW($target-px, $wrap-px) {
  @return $target-px / $wrap-px * 100vw;
}
// pxから%(hw)を計算して返す
@function getVH($target-px, $wrap-px) {
  @return $target-px / $wrap-px * 100hw;
}
