@import "lib/bootstrap.scss";
@import 'settings';
@import 'mixin';
@import 'module';


@import url('https://fonts.googleapis.com/css?family=Montserrat:400,600&display=swap');

html,body{
  font-family: "游ゴシック体", "YuGothic", "游ゴシック", "Yu Gothic", "メイリオ", "Hiragino Sans", sans-serif;
  font-weight: 400;
  //height: 100%;
  // min-height: 100%;
  line-height: 1.6;
  letter-spacing: 1px;
  font-size: 16px;
  color: $color-main-text;
}
body{
  background-image: url('../img/bg_map.png');
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: $color-main-bg;
  @include media-breakpoint-down(sm) {
    background-image: none;
    &:before {
      content: "";
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      background: url('../img/bg_map.png') center no-repeat;
      background-size: cover;
    }
  }
  a {
    text-decoration: none;
    color: $color-main-text;
    &:hover{

    }
    &:visited{
      color: $color-main-text;
    }
    img{
      &:hover{
        opacity: 0.8;
      }
    }
  }
}
ol,ul{
  list-style: none;
  margin: 0;
  padding: 0;
}
p{
  font-size: 1em;
}
h1,h2,h3,h4,h5,h6{
  font-family: $font-family-montserrat;
  font-weight: 600;
  letter-spacing: 0.2em;
}
h2{
  font-size: $font-size-40;
  letter-spacing: 0.3em;
  border-bottom: 1px solid $color-white;
  display: inline-block;
  padding-bottom: 11px;
  margin-bottom: 32px;
  &:after{
    // content: " ";
    // margin-right: -0.3em;
  }
  @include media-breakpoint-down(sm){
    font-size: $font-size-20;
    margin-bottom: 32px;
  }
}

.loader {
  position: fixed;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  background: #000;
  z-index: 9999;
  background-image: url('../img/bg_map.png');
  //background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  &__innr{
    text-align: center;
    opacity: 0;
    animation: 1s loaderimg forwards;
    &__img{
    }
    img{
      width: getVW(153px, 1440px);
      height: auto;
      @include media-breakpoint-down(sm){
        width: getVW(149px, 375px);
      }
    }
    p{
      opacity: 0;
      font-size: getVW(25px, 1440px);
      margin: getVW(35px, 1440px) 0 0 0;
      padding-left: getVW(22px, 1440px);
      animation: 1s loaderimg forwards;
      @include media-breakpoint-down(sm){
        font-size: getVW(22px, 375px);
        margin: getVW(26px, 375px) 0 0 0;
        padding-left: getVW(20px, 375px);
      }
    }
  }
}
@keyframes loaderimg {
  0% {opacity: 0}
  100% {opacity: 1}
}

.bg-ani {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100vh;
  background:linear-gradient(45deg,rgba(255,255,255,.1),rgba(0,0,0,0),rgba(255,255,255,.1), rgba(0,0,0,0));
  background-size: 200% 200%;
  opacity: 1;
  animation: huerotator 5s ease infinite;
}

@keyframes huerotator {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}


.header{
  background: $color-main-bg;
  position: relative;
  h1{
    margin: 0;
    padding: 0;
    position: absolute;
    left: 10px;
    top: 10px;
    padding: 0;
    margin: 0;
    z-index: 1031;
    @include media-breakpoint-down(sm){
      line-height: 0;
      left: 10px;
      top: 16px;
    }
    img{
      width: 67px;
      height: auto;
      @include media-breakpoint-down(sm){
        height: 30px;
        width: auto;
      }
    }
  }
  a{
    color: $color-white;
  }
  .navbar{
    padding: 0;
    &.nav-open{
      @include media-breakpoint-down(sm){
        background: $color-main-bg;
      }
    }
    .navbar-brand{
      position: absolute;
      left: 10px;
      top: 10px;
      padding: 0;
      margin: 0;
      @include media-breakpoint-down(sm){
        left: 10px;
        top: 15px;
        h1{
          line-height: 0;
          img{
            height: 30px;
            width: auto;
          }
        }
      }
    }
    .navbar-nav{
      font-family: $font-family-montserrat;
      font-size: $font-size-14;
      font-weight: 600;
      letter-spacing: 0.1em;
      line-height: 17px;
      @include media-breakpoint-down(sm){
        display: none;
      }
    }
    .nav-link{
      /*背景の基点とするためrelativeを指定*/
      position: relative;
      /*ボタンの形状*/
      display: inline-block;
      height: $font-size-14;
      line-height: $font-size-14;
      outline: none;
      text-align: center;
      span{
        display: block;
        position: absolute;
        /* 重なりを3Dで表示 */
        transform-style: preserve-3d;
        /* アニメーションの設定 数字が少なくなるほど早く回転 */
        transition: 0.5s;
      }
      &.rotatefront span:nth-child(1) {
        transform: rotateX(0deg);/*はじめは回転なし*/
        transform-origin: 0 50%  -25px;/* 回転する起点 */
       }

      /*hoverをした後の形状*/
      &.rotatefront:hover span:nth-child(1) {
          transform: rotateX(-90deg);/* X軸に-90度回転 */
      }

      /* 回転後 */
      &.rotatefront span:nth-child(2) {
          transform: rotateX(90deg);/*はじめはX軸に90度回転*/
          transform-origin: 0 50%  -25px;/* 回転する起点 */
      }

      /*hoverをした後の形状*/
      &.rotatefront:hover span:nth-child(2) {
          transform: rotateX(0deg);/* X軸に0度回転 */
      }
    }
    .navbar-toggler{
      margin: 15px 10px;
      padding: 0;
      &[aria-expanded="false"]{
        .navbar-toggler-icon {
         background-image: url("../img/icon_menu.svg");
        }
      }
      &[aria-expanded="true"]{
        .navbar-toggler-icon {
          background-image: url("../img/icon_close.svg");
        }
      }
      .navbar-toggler-icon {
        width: 28px;
        height: 27px;
        background-repeat: no-repeat;
        background-position: center center;
      }

    }
    .navbar-collapse{
      @include media-breakpoint-down(sm){
        background:  $color-main-bg;
      }
      &.collapsing {
        position: relative;
        height: 0;
        overflow: hidden;
        transition: all 0.35s ease-in-out;
      }
      .navbar-nav-sp__list__item{
        opacity: 0;
        //transition: all 2s ease;
        transform: translate(-40%, 0);
        transition: 1s;
      }
      .navbar-nav-sp__btn{
        opacity: 0;
        transition: all 2s ease;
      }
      &.show{
        .navbar-nav-sp__list__item{
          opacity: 1;
          transform: translate(0, 0);
        }
        .navbar-nav-sp__btn{
          opacity: 1;
        }
      }
    }
    .navbar-nav-sp{
       display: none;
       width: 100%;
       height: 100vh;
       background:  $color-main-bg;
       overflow-y:auto;
       @include media-breakpoint-down(sm){
         display: block;
       }
       &__list{
         font-family: $font-family-montserrat;
         font-size: $font-size-20;
         letter-spacing: 0.3rem;
         &__item{
           border-bottom: 1px solid rgba(255, 255, 255, .5);
           &>a{
             display: block;
             padding: 12px 20px;
             text-decoration: none;
             background-image: url(../img/icon_arrow_r_m.svg);
             background-position:  95% center;
             background-repeat: no-repeat;
             &[data-toggle="collapse"]{
               background-image: url(../img/icon_minus.svg);
             }
             &[aria-expanded=false]{
               background-image: url(../img/icon_plus.svg);
             }
             &[aria-expanded=ture]{
                background-image: url(../img/icon_plus.svg);
              }
           }
           &__list{
             background: #28295D;
             font-size: 15px;
             letter-spacing: 0.2rem;
             &__item{
               padding: 10px 60px;
               border-bottom: 1px solid rgba(255, 255, 255, .5);
               &:first-child{
                 padding: 10px 40px;
               }
               &:last-child{
                 border-bottom: none;
               }
               a{
                 display: block;
                 padding-left: 21px;
                 background-image: url(../img/icon_arrow_r_s.svg);
                 background-position:  0 center;
                 background-repeat: no-repeat;
                 text-decoration: none;
               }
             }
           }
         }
       }
       &__btn{
         padding: 40px 20px;
       }
       &__contact{
         display: block;
         background: $color-white;
         padding: 19px 0;
         text-align: center;
         font-size: $font-size-14;
         color: $color-bule-text;
         position: relative;
         font-weight: bold;
         &:after{
           position: absolute;
           right: 10px;
           top: 50%;
           content: " ";
           width: 50px;
           height: 1px;
           border-top: 1px solid $color-bule-text;
         }
         a{
           display: block;
         }
       }
    }
    .nav-item{
      padding: 20px 12px;
      a{
       padding: 0;
      }
      &__contact{
        background: $color-white;
        margin-left: 20px;
        padding: 20px 20px;
        a{
          color: $color-bule-text;
          padding-right: 40px;
          position: relative;
          &:after{
            position: absolute;
            right: 0;
            top: 50%;
            content: " ";
            width: 30px;
            height: 1px;
            border-top: 1px solid #130421;
          }
        }
      }
    }

    .dropdown{
      &:hover>.dropdown-menu {
        visibility: visible;
        opacity: 1;
        top: 95%;
        z-index: 2;
      }
    }
    .dropdown-menu{
      border-radius: 0;
      padding: 23px 26px;
      display:block;
      opacity: 0;
      visibility:hidden;
      top: 110%;
      left: -0rem;
      min-width: 12rem;
      transition: 0.5s;
      z-index: 1;
      .dropdown-item{
        display: inline-block;
        color: #170826;
        font-size: $font-size-14;
        padding-bottom: 3px;
        margin-bottom: 10px;
        background: none;
        &:last-child{
          margin-bottom: 0;
        }
        &:visited{
          color: #170826;
        }
        span{
          &:hover{
            border-bottom: 1px solid #170826;
          }
        }
      }
    }
  }
}

.ttl-left-space{
  position: relative;
  &__innr{
    width: 1118px;
    margin: 0 auto;
    @include media-breakpoint-down(sm){
      width: auto;
      margin: 0 $wrap-lr-space;
    }
  }
  &__ttl{
     margin-bottom: 0;
     position: relative;
     &:before{
       position: absolute;
       left: -1000px;
       bottom: -1px;
       content: "";
       width: 1000px;
       height: 1px;
       border-bottom: 1px solid $color-white;
     }
   }
}
.ttl-right-space{
  position: relative;
  &__innr{
    width: 1118px;
    margin: 0 auto;
    @include media-breakpoint-down(sm){
      margin: 0 $wrap-lr-space;
    }
  }
  &__ttl{
     margin-bottom: 0;
     position: relative;
     &:before{
       position: absolute;
       right: -1000px;
       bottom: -1px;
       content: "";
       width: 1000px;
       height: 1px;
       border-bottom: 1px solid $color-white;
     }
   }
}

.mainvisual{
  min-height: 600px;
  height: 100%;
  //display: table;
  width: 100%;
  position: relative;
  &__innr{
    width: 100%;
    //display: table-cell;
    //vertical-align: middle;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    z-index: 5;
    @include media-breakpoint-down(sm){
      top: 20%;
      left: 50%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
    }
  }
  &__slider{
    overflow: hidden;
    //display: table-cell;
    //vertical-align: middle;
    width: 100%;
    text-align: center;
    position: absolute;
    top: 52%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    z-index: 1;
    .bx-wrapper{
      margin-bottom: 0;
      img{
        max-width: initial;;
      }
    }
    .bx-viewport {
      max-width: 100% !important;
    }
    .bx-viewport {
      overflow: visible!important;
      width: 100% !important;
    }
    &__list{
      margin: 0;
      &__item{
        //transform:skewX(-10deg);
        //overflow:hidden;
        overflow: hidden;
        position: relative;
        width: 2000px!important;
        height: 375px!important;
        @include media-breakpoint-down(sm){
          width: 1200px!important;
          height: 225px!important;
        }
        &__wrap{
          transform:skewX(-15deg);
          overflow:hidden;
          height: 100%;
          overflow: hidden;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          transition: width 2s linear;
          -webkit-transition: width 2s linear;
          //&.active{
          //  width: 100%;
          //  transition: width 2s ease;
          //  -webkit-transition: width 2s ease;
          //}
          &.hide{
            width: 0;
            transition: width 2s linear;
            -webkit-transition: width 2s linear;
          }
        }
        img{
          //display: none;
          transform:skewX(15deg);
          &.show{
            //display: block;
            //animation-name: kai-fadein;
            //animation-duration: 3s;
          }
          width: 2000px!important;
          @include media-breakpoint-down(sm){
            width: 1200px!important;
          }
        }
        //@keyframes kai-fadein {
        //  from {
        //    opacity: 0;
        //  }
        //  to {
        //    opacity: 1;
        //  }
        //}
      }
    }
  }
  &__cacth{
    font-size: $font-size-80;
    font-family: $font-family-montserrat;
    font-weight: 600;
    line-height: 98px;
    letter-spacing: 0.3em;
    @include media-breakpoint-down(md){
      font-size: $font-size-60;
      line-height: 70px;
      letter-spacing: 0.2em;
    }
    @include media-breakpoint-down(sm){
      font-size: $font-size-30;
      line-height: 40px;
      letter-spacing: 0.14em;
    }
  }
  &__sub{
    font-size: $font-size-30;
    line-height: 45px;
    letter-spacing: 0.2em;
    @include media-breakpoint-down(md){
      font-size: $font-size-24;
      line-height: 40px;
      letter-spacing: 0.2em;
    }
    @include media-breakpoint-down(sm){
      font-size: $font-size-16;
      line-height: 24px;
      letter-spacing: 0.2em;
    }
  }
  .top_cacth{
    display: none;
    &.show{
      display: block;
    }
    span{
      opacity: 0;
    }
    &.appeartext span{ animation:text_anime_on 1s ease-out forwards; }
    @keyframes text_anime_on {
      0% {opacity:0;}
      100% {opacity:1;}
    }
  }
  &__scroll{
    width: 100px;
    text-align: center;
    position: absolute;
    margin-left: -50px;
    left: 50%;
    bottom: 5%;
    @include media-breakpoint-down(sm){
      font-size: 13px;
    }
    span{
      font-family: $font-family-montserrat;
      font-size: $font-size-12;
      line-height: $font-size-12;
    }
    &__bar{
      position: relative;
      height: 122px;
      overflow: hidden;
      img{
        opacity: 0.5;
      }
      &:before{
       content: "";
       position: absolute;
       top: 0;
       left: 50%;
       width: 1px;
       height: 122px;
       background: #FFF;
       opacity: 0.4;
      }
      &:after{
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        width: 1px;
        height: 122px;
        background: #FFF;
        animation: mainvisualscroll 2s ease-in-out infinite normal;
        opacity:1;
      }
    }
    @keyframes mainvisualscroll{
      0%{
        height:122px;
        top:-122px;
        opacity: 1;
      }
      100%{
        height:122px;
        top:122px;
        opacity: 1;
      }
    }
  }
}

.whoweare{
  padding: 100px 0 38px 0;
  @include media-breakpoint-down(sm){
    padding: 38px 0 38px 0;
  }
  &__innr{

  }
  &__header{
    h3{

    }
  }
  &__cts{
    max-width: 1118px;
    margin: 0 auto 130px;
    position: relative;
    padding-top: 89px;
    @include media-breakpoint-down(md){
      width: auto;
      margin: 0 $wrap-lr-space 80px;
    }
    @include media-breakpoint-down(sm){
      width: auto;
      padding-top: 0;
      display: block;
    }
    &__ttl{
      font-size: $font-size-40;
      line-height: 60px;
      letter-spacing: 0.2em;
      margin-bottom: 42px;
      @include media-breakpoint-down(sm){
         letter-spacing: 0.2em;
         line-height: 42px;
         font-size: $font-size-28;
         margin-bottom: 28px;
         margin-top: 40px;
      }
    }
    &__text{
      line-height: 36px;
      letter-spacing: 0.3em;
      @include media-breakpoint-down(sm){
       letter-spacing: 0.1em;
       font-size: $font-size-14;
      }
    }
    &__btn-more{
      margin-bottom: 63px;
    }
  }
  &__award{
    h3{
      font-size: $font-size-20;
      &:before{
         content: '：';
      }
      @include media-breakpoint-down(sm){
        font-size: $font-size-18;
      }
    }
    &__list{
      display: flex;
      @include media-breakpoint-down(sm){
        display: block;
      }
      &__item{
        padding: 0 15px;
        border-left: 1px solid $color-white;
        &:first-child{
          border: none;
          padding-left: 0;
        }
        @include media-breakpoint-down(sm){
          width: 100%;
          font-size: $font-size-14;
          border-left: none;
          padding-left: 0;
          margin-bottom: 5px;
        }
      }
    }
  }
  &__topic{
    &__data{
      position: absolute;
      right: 0;
      top: 0;
      @include media-breakpoint-down(sm){
        @include clearfix();
        position: relative;
        margin-top: 59px;
      }
    }
    &__item{
      width: 362px;
      margin-bottom: 57px;
      @include media-breakpoint-down(md){
        width: 300px;
      }
      @include media-breakpoint-down(sm){
        width: getVW(250px, $w_design_sp);
        margin-bottom: 30px;
        padding: 11px;
        img{
          width: 100%;
          height: getVW(93px, $w_design_sp);
          object-fit: cover;
          margin-bottom: 0;
        }
      }
      &1{
        @include media-breakpoint-down(sm){
          margin: 0 0 30px 0;
          float: right;
        }
        opacity: 0;
      }
      &2{
        @include media-breakpoint-down(sm){
          float: left;
        }
        opacity: 0;
        @include media-breakpoint-down(sm){
          img{
            object-position: 100% 0;
          }
        }
      }

      h3{
        font-size: $font-size-18;
        text-align: center;
        @include media-breakpoint-down(sm){
          position: absolute;
          left: 1px;
          bottom: 15px;
          margin: 0;
          font-size: $font-size-12;
          color: $color-white;
          background: linear-gradient(
              -90deg
            , rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
          padding: 5px 10px;
          text-align: left;
          font-weight: 400;
        }
      }
      p{
        font-size: $font-size-14;
        text-align: center;
      }
    }
    &__radar{
      @include media-breakpoint-down(sm){
        margin: 67px 0 32px;
        text-align: center;
        &__innr{
          display: inline-block;
          position: relative;
        }
      }
    }
    &__map{
      position: absolute;
      top: 83px;
      right: 401px;
      opacity: 0;
      @include media-breakpoint-down(sm){
        position: static;
        margin: 0 auto;
      }
    }
    &__line1{
      position: absolute;
      top: 134px;
      right: 362px;
      @include media-breakpoint-down(sm){
        margin-top: -10px;
        margin-right: 7px;
        top: 50%;
        right: 50%;
      }
       fill: none;
       stroke: #8F64CE;
       stroke-dasharray: 400px;
       stroke-dashoffset: 400px;
       animation: line_animation 2s both;
    }
    &__line2{
      position: absolute;
      top: 212px;
      right: 362px;
      fill: none;
      stroke: #8F64CE;
      stroke-dasharray: 500px;
      stroke-dashoffset: 0;
      animation: line_animation 2s both;
      @include media-breakpoint-down(sm){
        margin-top: -10px;
        margin-right: 7px;
        top: 50%;
        right: 50%;
      }
    }
    @keyframes line_animation {
      0% {
        stroke-dashoffset: 400px;
      }
      100% {
        stroke-dashoffset: 0;
      }
    }
    @keyframes line_animation_sp {
      0% {
        stroke-dashoffset: 0;
      }
      100% {
        stroke-dashoffset: 500px;
      }
    }
  }
}

.mission{
  padding: 138px 0;
  background-image: url('../img/bg_mission.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  @include media-breakpoint-up(md){
    background-image: url('../img/bg_mission.jpg');
    background-size: cover;
  }
  @include media-breakpoint-up(lg){
    background-image: url('../img/bg_mission.jpg');
    background-attachment: fixed;
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
  }
  &:before{

  }
  &__innr{

  }
  &__header{
    display: flex;
    justify-content: flex-end;
    h2{
      width: 50%;
      @include media-breakpoint-down(sm){
        width: 100%;
        margin-left: $wrap-lr-space;
      }
    }
  }
  &__cts{
    width: 1200px;
    margin: 0 auto 130px;
    display: flex;
    @include media-breakpoint-down(md){
      width: auto;
      margin: 0 $wrap-lr-space 80px;
    }
    @include media-breakpoint-down(sm){
      display: block;
    }
    &__col1{
      width: 50%;
      text-align: center;
      img{
        width: getVW(423px, 1400px);
        height: auto;
      }
      @include media-breakpoint-down(md){
        img{
          width: 80%;
        }
      }
      @include media-breakpoint-down(sm){
          width: 100%;
          text-align: center;
          margin-top: 36px;
          img{
            width: 80%
          }
      }
    }
    &__col2{
      margin-top: 66px;
      width: 50%;
      @include media-breakpoint-down(sm){
        width: 100%;
      }
    }
    &__sub-ttl{
      font-family: $font-family-montserrat;
      font-size: $font-size-20;
      letter-spacing: 0.3em;
      margin-bottom: 0;
      @include media-breakpoint-down(sm){
        font-size: $font-size-14;
        margin-bottom: 7px;
      }
    }
    &__ttl{
      font-size: $font-size-40;
      margin-bottom: 43px;
      @include media-breakpoint-down(sm){
        font-size: $font-size-28;
        line-height: 42px;
        margin-bottom: 37px;
      }
    }
    &__text{
      line-height: 36px;
      @include media-breakpoint-down(sm){
        font-size: $font-size-14;
        line-height: 30px;
      }
    }
  }
  &__project{
    max-width:1336px;
    margin: 0 auto;
    @include media-breakpoint-down(md){
      width: auto;
      margin: 0;
      overflow: hidden;
    }
    h3{
      font-size:  $font-size-20;
      &:before{
         content: '：';
      }
      @include media-breakpoint-down(sm){
        margin-bottom: 20px;
      }
    }
    &__list{
      display: flex;
      @include media-breakpoint-down(sm){
        display: block;
        position: relative;
        left: 10vw; /* (100vw - SlideWidth) / 2 */
        overflow: visible !important;
      }
      &__item{
        width: 25%;
        height: 440px;
        padding: 36px 38px 140px;
        background-color: #C4C4C4;
        position: relative;
        color: $color-white;
        background-size: cover !important;
        &:nth-child(2n){
          background: #E6E6E6;
        }
        &.bg01{
          background: linear-gradient(rgba(1, 0, 51, 0), rgba(1, 0, 51, .8)), url(../img/img_mission_project_pickup_kankyo.jpg);
        }
        &.bg02{
          background: linear-gradient(rgba(1, 0, 51, 0), rgba(1, 0, 51, .8)), url(../img/img_mission_project_pickup_miyagioyster.jpg);
        }
        &.bg03{
          background: linear-gradient(rgba(1, 0, 51, 0), rgba(1, 0, 51, .8)), url(../img/img_mission_project_pickup_col.jpg);

        }
        &.bg04{
          background: linear-gradient(rgba(1, 0, 51, 0), rgba(1, 0, 51, .8)), url(../img/img_mission_project_pickup_oysterresort.jpg);

        }
        &__innr{
          position: absolute;
          left: 40px;
          bottom: 39px;
          @include media-breakpoint-down(sm){
            left: 20px;
          }
        }
        &__ttl{
          font-size: $font-size-18;
          margin-bottom: 16px;
          position: relative;
          &:after{
            content: "";
            border-top: 1px solid $color-white;
            width: 110px;
            height: 1px;
            position: absolute;
            bottom: -5px;
            left: 0;
          }
        }
        &__text{
          font-size: $font-size-13;
          margin-bottom: 30px;
        }
        &__link{
          display: block;
          font-size: $font-size-18;
          width: 92px;
          height: 92px;
          line-height: 92px;
          border-radius: 46px;
          border: 1px solid $color-white;
          text-align: center;
          &:hover{
            opacity: 0.8;
          }
          img{
            display: inline;
          }
        }
      }
    }
  }
}

.products{
  width: 1026px;
  margin: 0 auto 190px;
  padding: 149px 0 0;
  @include media-breakpoint-down(md){
    width: auto;
    margin: 0 $wrap-lr-space 190px;
  }
  @include media-breakpoint-down(sm){
    width: auto;
    padding: 127px 0 143px 0;
    margin: 0 $wrap-lr-space 0;
  }
  &__header{
    margin-bottom: 50px;
    @include media-breakpoint-down(sm){
      margin-bottom: 30px;
    }
    p{
      font-size: $font-size-15;
      font-weight: 400;
      letter-spacing: 0.2em;
      @include media-breakpoint-down(md){
        font-size: $font-size-15;
      }
      @include media-breakpoint-down(sm){
        font-size: $font-size-14;
      }
    }
  }
  &__list{
    display: flex;
    justify-content: space-between;
    //margin-bottom: 113px;
    @include media-breakpoint-down(sm){
      display: block;
    }
    &__item{
      width: 300px;
      @include media-breakpoint-down(md){
        width: 30%;
      }
      @include media-breakpoint-down(sm){
        width: 100%;
        margin-top: 70px;
        margin-bottom: 30px;
        padding: 11px;
        img{
          width: 100%;
          height: auto;
          object-fit: cover;
        }
      }
      h3{
        font-size: $font-size-24;
        text-align: center;
        @include media-breakpoint-down(sm){
          position: absolute;
          left: 0px;
          top: -34px;
          border-left: 2px solid $color-white;
          //width: 100%;
          margin: 0;
          font-size: $font-size-16;
          color: $color-white;
          padding: 0 10px;
        }
      }
      p{
        font-size: $font-size-14;
        text-align: center;
      }
    }
  }
  &__pickup{
    &__header{
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px;
      align-items: flex-end;
      h3{
        font-size:  $font-size-20;
        margin-bottom: 0;
        &:before{
          content: '：';
        }
        @include media-breakpoint-down(sm){
          margin-bottom: 15px;

        }
      }
    }
    &__wrap{
      @include media-breakpoint-down(sm){
        margin: 0 -$wrap-lr-space;
        overflow: hidden;
      }
    }
    &__list{
      display: flex;
      justify-content: space-between;
      @include media-breakpoint-down(sm){
        display: block;
        position: relative;
        left: 10vw; /* (100vw - SlideWidth) / 2 */
        overflow: visible !important;
      }
      &__item{
        width: 300px;
        background: $color-white;
        color: $color-black;
        border-radius: 15px;
        @include media-breakpoint-down(md){
          width: 30%;
        }
        a{
          color: $color-black;
          display: block;
          &:visited{
            color: $color-black;
          }
          &:hover{
            text-decoration: none;
            opacity: 0.8;
          }
        }
        &__img{
          img{
            border-radius: 15px 15px 0 0;
            width: 300px;
            height: 177px;
            object-fit: cover;
            @include media-breakpoint-down(md){
              width: 100%;
              height: auto;
            }
          }
        }
        &__innr{
          position: relative;
          padding: 8px 22px 16px 22px;
        }
        &__ttl{
          font-size: $font-size-16;
          font-weight: 600;

          letter-spacing: 0.2em;
          vertical-align: middle;
          // background-image: url(../img/icon_arrow_sin_r.png);
          // background-repeat: no-repeat;
          // background-position: 93% 90%;
          &:after{
            vertical-align: middle;
            display: inline-block;
            //content: url(../img/icon_arrow_sin_r.png);
            position: absolute;
            bottom: 10px;
            right: 15px;
          }
          @include media-breakpoint-down(sm){
            height: 78px;
          }
        }
        &__capacity{
          font-size: $font-size-12;
          position: absolute;
          top: 12px;
          right: 15px;
        }
        &__price{
          margin-top: 10px;
          font-weight: 600;
          font-size: $font-size-20;
          letter-spacing: 0.2rem;
        }


      }
    }
  }
}

.news{
  &__innr{
    width: 950px;
    margin: 0 auto;
    @include media-breakpoint-down(md){
      width: 100%;
      padding-left: $wrap-lr-space;
      padding-right: $wrap-lr-space;
    }
    @include media-breakpoint-down(sm){
      width: 100%;
      padding-left: $wrap-lr-space;
      padding-right: $wrap-lr-space;
    }
  }
  &__header{
    margin-bottom: 30px;
  }
  h2{

  }
  &__list{
    margin-bottom: 47px;
    @include media-breakpoint-down(sm){
      margin-bottom: 0;
    }
    &__item{
      border-top: 1px solid $color-white;
      &:last-child{
        border-bottom: 1px solid $color-white;
      }
      a{
        display: block;
        padding: 17px 0;
        cursor: pointer;
        @include media-breakpoint-down(sm){
          position: relative;
          padding: 17px 55px 17px 0;
          &:after{
            vertical-align: middle;
            display: inline-block;
            content: url(../img/icon_arrow_sin_w_r.png);
            position: absolute;
            top: 50%;
            right: 0;
            margin-top: -10px;
          }
        }
      }
      &__innr{
        display: flex;
      }
      &__th{
        width:88px;
        height:88px;
        margin-right: 30px;
        img{
          object-fit: cover;
        }
      }
      &__cts{
        a{
          @include media-breakpoint-down(sm){
            padding: 17px 80px 17px 0;
          }
        }
        &__date{
          margin-bottom: 10px;
          time{
            font-family: $font-family-montserrat;
            letter-spacing: 0.2em;
            font-weight: 600;
          }
        }
        &__ttl{
          font-size: $font-size-14;
          font-weight: 600;
          margin-bottom: 10px;
        }
        &__text{
          font-size: $font-size-14;
          margin-bottom: 0;
        }
      }
    }
  }
  &__btn-more{
    @include media-breakpoint-down(sm){
      margin-bottom: 30px;
    }
  }
}

.topics{
  background: rgba(86, 52, 118, 0.5);
  padding: 25px 0;
  h3{
    font-size: $font-size-20;
    margin: 0 47px 26px;
    @include media-breakpoint-down(sm){
      margin: 0 $wrap-lr-space 19px;
    }
   &:before{
     content: '：';
   }
  }
  &__slider{
    &__item{
      width: 350px;
      border-radius: 15px;
      background: $color-white;
      a{
        color: $color-black;
        display: block;
        &:visited{
          color: $color-black;
        }
        &:hover{
          text-decoration: none;
          opacity: 0.8;
        }
      }
      &__img{
        img{
          border-radius: 15px 15px 0 0;
          width: 350px;
          height: 196px;
          object-fit: cover;
        }
      }
      &__ttl{
        font-size: $font-size-18;
        font-weight: 600;
        padding: 9px 65px 9px 22px;
        letter-spacing: 0.2em;
        vertical-align: middle;
        background-image: url(../img/icon_arrow_sin_r.png);
        background-repeat: no-repeat;
        background-position: 93% 19px;
        // &:after{
        //   vertical-align: middle;
        //   display: inline-block;
        //   content: url(../img/icon_arrow_sin_r.png);
        //   position: absolute;
        //   height: 7px;
        //   top: 50%;
        //   right: 22px;
        // }
      }
    }
  }
}

.footer{
  padding-top: 69px;
  @include media-breakpoint-down(md){
    padding-right: $wrap-lr-space;
    padding-left: $wrap-lr-space;
    padding-top: 30px;
  }
  &__wrap{
    width: 1118px;
    margin: 0 auto;
    @include media-breakpoint-down(md){
      width: 100%;
    }
  }
  &__logo{
    text-align: center;
    margin-bottom: 26px;
    img{
      margin-bottom: 26px;
      width: 121px;
      height: auto;
      @include media-breakpoint-down(sm){
        width: 84px;
      }
    }
    p{
      font-size: 1em;
    }
  }
  &__sns{
    text-align: center;
    margin-bottom: 40px;
    &__list{
      display: flex;
      justify-content: center;
      align-items: center;
      @include media-breakpoint-down(sm){
        margin: 0 0;
      }
      &__item{
        padding-right: 27px;
        &:last-child{
          padding-right: 0;
          padding-left: 27px;
          border-left: 1px solid $color-white;
        }
        @include media-breakpoint-down(sm){
          padding-right: 15px;
          &:last-child{
            padding-right: 0;
            padding-left: 15px;
          }
           img{
             width: 100%;
             height: auto;
           }
        }
      }
    }
  }
  &__sitemap{
    border-top: 1px solid $color-white;
    border-bottom: 1px solid $color-white;
    padding: 25px 0;
    margin-bottom: 40px;
    @include media-breakpoint-down(sm){
      margin-bottom: 20px;
    }
    .container-fluid{
      padding-left: 0;
      padding-right: 0;
      @include media-breakpoint-down(sm){
        padding-left: $grid-gutter-width / 2;
        padding-right: $grid-gutter-width / 2;
      }
    }
    &__list{
      display: flex;
      justify-content: flex-start;
      &__item{
        width: 18%;
        font-family: $font-family-montserrat;
        font-weight: 600;
        font-size: $font-size-14;
        letter-spacing: 1px;
        @include media-breakpoint-down(md){
          width: 22%;
        }
        &__child{
          margin-top: 17px;
          &__item{
            font-size: $font-size-12;
            margin-bottom: 10px;
          }
        }
      }
    }
    &__contact{
      border-left: 1px solid $color-white;
      @include media-breakpoint-down(sm){
        border-left: none;
      }
      &__innr{
        padding: 0 20px 20px;
        @include media-breakpoint-down(md){
          padding: 0 0 0;
        }
        @include media-breakpoint-down(sm){
          padding: 0 0 20px;
          margin: 0 auto;
          width: 90%;
        }
      }
      &__ttl{

        font-family: $font-family-montserrat;
        font-weight: 600;
        font-size: $font-size-14;
        letter-spacing: 1px;
        @include media-breakpoint-down(sm){
          text-align: center;
        }
      }
      &__info{
        font-size: $font-size-12;
        margin-top: 17px;
        @include clearfix();
        &__dt{
          float: left;
          width: 20%;
        }
        &__dd{
          margin-left: 20%;

        }
      }
      &__link{
        a{
          display: inline-block;
          color: $color-main-bg;
          background: $color-white;
          font-family: $font-family-montserrat;
          font-weight: 600;
          font-size: $font-size-14;
          padding: 19px 75px 19px 25px;
          position: relative;
          @include media-breakpoint-down(sm){
            width: 100%;
          }
          &:after{
            position: absolute;
            right: 13px;
            top: 50%;
            content: " ";
            width: 50px;
            height: 1px;
            border-top: 1px solid $color-main-bg;
          }
          &:hover{
            text-decoration: none;
            opacity: 0.8;
          }
          &:visited{
            color: $color-main-bg;
          }
        }
      }
    }
  }
  &__office{
    margin-bottom: 40px;
    .container-fluid{
      padding-left: 0;
      padding-right: 0;
      @include media-breakpoint-down(sm){
        padding-left: $grid-gutter-width / 2;
        padding-right: $grid-gutter-width / 2;
      }
    }
    &__info{
        border-left: 1px solid $color-white;
        @include clearfix();
        &:first-child{
          border-left: none;
        }
        @include media-breakpoint-down(sm){
          border-left: none;
          padding-bottom: 20px;
          margin-bottom: 20px;
          border-bottom: 1px solid $color-white;
        }
      &__innr{
        max-width: 320px;
        @include media-breakpoint-down(md){
          max-width: 100%;
          width: 100%;
        }
        @include media-breakpoint-down(sm){
          max-width: initial;
          width: auto;

        }
        &-left{
          float: left;
          @include media-breakpoint-down(md){
            float: none;
          }
        }
        &-center{
          margin: 0 auto;
          @include media-breakpoint-down(md){

          }
        }
        &-right{
          float: right;
          //padding-left: 20px;
          @include media-breakpoint-down(md){
            float: none;
            padding-left: 0px;
          }
        }
      }
      &__list{
        display: flex;
        justify-content: space-between;
      }
      &__map{

        img{
          @include media-breakpoint-down(md){
             width: 100%;
             height: auto;
          }
        }
        iframe{
          filter: grayscale(1);
          @include media-breakpoint-down(md){
            width: 100%;
            height: auto;
          }
        }
      }
      &__name{
        margin: 10px 0;
        font-weight: bold;
        @include media-breakpoint-down(md){
          margin: 10px 0;
        }
      }
      &__address{
        font-size: $font-size-14;
        word-break: break-all;
      }
    }
  }
  &__copy{
    padding-top: 40px;
    border-top: 1px solid $color-border;
    @include media-breakpoint-down(sm){
      padding-top: 0px;
      border-top: none;
    }
    text-align: center;
    font-size: $font-size-12;
  }
}

.lower{
  padding-top: 105px;
  &__cts{
    width: 1118px;
    margin: 0 auto;
    @include media-breakpoint-down(sm){
      width: auto;
      padding-right: $wrap-lr-space;
      padding-left: $wrap-lr-space;
    }
  }
  &__ttl{
    margin-bottom: 50px;
    position: relative;
    h1{
      font-size: $font-size-50;
      span{
        margin-top: 6px;
        display: block;
        font-size: 36%;
        font-weight: 200;
        letter-spacing: 2px;
        color: #AEAEAE;
      }
    }
    @include media-breakpoint-down(sm){
      h1{
        font-size: $font-size-36;
        span{
          margin-top: 6px;
          display: block;
          font-size: 36%;
          font-weight: 200;
          letter-spacing: 2px;
        }
      }
    }
    &__sns__list{
      position: absolute;
      top: 50%;
      right:0;
      transform: translate(0%, -50%);
      -webkit-transform: translate(0%, -50%);
      -ms-transform: translate(0%, -50%);
      @include media-breakpoint-down(sm){
        position: static;
        padding: 0 $wrap-lr-space;
      }
      &__item{
        display: inline-block;
        padding-right: 25px;
        @include media-breakpoint-down(sm) {
          padding-right: 20px;
          img{
            width: 90%;
          }
        }
        &__ttl{
          font-size: $font-size-14;
          vertical-align: middle;
        }
      }
    }

  }
  &__mv{
    opacity: 0;
    transition: all 1s;
    width: 100%;
    height: 500px;
    margin-bottom: 100px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    &.show{
      opacity: 1;
    }
    @include media-breakpoint-down(sm){
        height: 350px;
        margin-bottom: 60px;
    }
    &.about{
      background-image: url(../img/mv_about.jpg);
      @media screen and (-webkit-min-device-pixel-ratio:2),
      (min-resolution: 2dppx){
        background-image: url(../img/mv_about.jpg);
      }
      @include media-breakpoint-down(sm){
        background-image: url(../img/mv_about@sp.jpg);
        @media screen and (-webkit-min-device-pixel-ratio:2),
        (min-resolution: 2dppx){
          background-image: url(../img/mv_about@sp2x.jpg);
        }
      }
    }
    &.project{
      background-image: url(../img/mv_project.jpg);
      @media screen and (-webkit-min-device-pixel-ratio:2),
      (min-resolution: 2dppx){
        background-image: url(../img/mv_project.jpg);
      }
      @include media-breakpoint-down(sm){
        background-image: url(../img/mv_project@sp.jpg);
        @media screen and (-webkit-min-device-pixel-ratio:2),
        (min-resolution: 2dppx){
          background-image: url(../img/mv_project@sp2x.jpg);
        }
      }
    }
    &.factory{
      background-image: url(../img/mv_factory.jpg);
      @media screen and (-webkit-min-device-pixel-ratio:2),
      (min-resolution: 2dppx){
        background-image: url(../img/mv_factory.jpg);
      }
      @include media-breakpoint-down(sm){
        background-image: url(../img/mv_factory@sp.jpg);
        @media screen and (-webkit-min-device-pixel-ratio:2),
        (min-resolution: 2dppx){
          background-image: url(../img/mv_factory@sp2x.jpg);
        }
      }
    }
    &.product{
      background-image: url(../img/mv_products.jpg);
      @media screen and (-webkit-min-device-pixel-ratio:2),
      (min-resolution: 2dppx){
        background-image: url(../img/mv_products.jpg);
      }
      @include media-breakpoint-down(sm){
        background-image: url(../img/mv_products@sp.jpg);
        @media screen and (-webkit-min-device-pixel-ratio:2),
        (min-resolution: 2dppx){
          background-image: url(../img/mv_products@sp2x.jpg);
        }
      }
    }
    &__innr{
      position: relative;
      height: 100%;
    }
    &__copy{
      position: absolute;
      left: 0;
      bottom: 30px;
      &__text{

      }
      &__text__p{
        display: inline-block;
        color: $color-purple-text;
        font-size: $font-size-30;
        padding: 5px 10px 5px;
        font-weight: bold;
        letter-spacing: 2px;
        position: relative;
        &::before{
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 0;
          height: 100%;
          background: $color-white;
          transition: 1.5s;
        }
        &.bg-slidein::before{
          width: 100%;
        }
        span{
          opacity: 0;
        }
        &.appeartext{
          span{
            animation:text_anime_on 1s ease-out forwards;
          }
        }
        @keyframes text_anime_on {
          0% {opacity:0;}
          100% {opacity:1;}
        }
      }
      @include media-breakpoint-down(sm){
        left: $wrap-lr-space;
        bottom: 10px;
        p{
          font-size: $font-size-22;
          padding: 5px 5px 5px;
          margin-bottom: 10px;
        }
      }
    }
  }
  &__section{
    overflow: hidden;
    padding-top: 100px;
    margin-top: -100px;
    &__innr{
      width: 1118px;
      margin: 0 auto 200px;
      position: relative;
      @include media-breakpoint-down(md){
        width: auto;
        margin: 0 $wrap-lr-space 80px;
      }
      @include media-breakpoint-down(sm){
        margin: 0 $wrap-lr-space 40px;
        display: block;
      }

    }
    &__innr-mini{
      width: 1118px;
      margin: 0 auto 100px;
      position: relative;
      @include media-breakpoint-down(md){
        width: auto;
        margin: 0 $wrap-lr-space 40px;
      }
      @include media-breakpoint-down(sm){
        display: block;
      }

    }
    &__innr-article{
      width: 800px;
      margin: 0 auto 100px;
      position: relative;
      @include media-breakpoint-down(md){
        width: auto;
        margin: 0 $wrap-lr-space 40px;
      }
      @include media-breakpoint-down(sm){
        display: block;
      }

    }
    &__lead{
      line-height:2;
      letter-spacing:2px;
      @include media-breakpoint-down(md) {
        line-height:1.6;
        letter-spacing:1px;
      }
    }
    &__header{
      display: flex;
      &.header-left{

      }
      &.header-right{
        justify-content: flex-end;
      }
      &__innr{
        width: 500px;
        @include media-breakpoint-down(sm){
          width: 100% !important;
        }
        &.col-l{
          width: 513px;
        }
        &.col-m{
        }
        &.col-s{
          width: 430px;
        }
      }
      &__ttl{
        position: relative;
        margin-bottom: 0;
        &:before{
          position: absolute;
          right: -1000px;
          bottom: -1px;
          content: "";
          width: 1000px;
          height: 1px;
          border-bottom: 1px solid $color-white;
        }
        span{
          font-size: 80%;
          margin: 0 0 0 20px;
          &:before, &:after{
            content: '-';
          }
        }
      }
      .header-left &__ttl{
        &:before{
          position: absolute;
          left: -1000px;
          bottom: -1px;
          content: "";
          width: 1000px;
          height: 1px;
          border-bottom: 1px solid $color-white;
        }
      }
      &__ttl-s{
        border-bottom: 1px solid $color-white;
        font-size: $font-size-30;
        padding-bottom: 10px;
        letter-spacing: 5px;
        @include media-breakpoint-down(sm){
          font-size: $font-size-18;
          line-height: 1.6;
        }
      }

    }
    &__header-bgimg{
      margin-left: -$wrap-lr-space;
      margin-right: -$wrap-lr-space;
      position: relative;
      &__ttl{
        position: absolute;
        left:0;
        bottom:25px;
        margin: 0;
        padding: 0 $wrap-lr-space;
      }
    }
    &__cts{
      display: flex;
      justify-content: space-between;
      @include media-breakpoint-down(sm){
         justify-content: normal;
         flex-direction: column;
      }
      &__col{
        width: 500px;
        margin-bottom: 40px;
        @include media-breakpoint-down(sm){
          width: 100%!important;
          flex: 1;
        }
        &.col-ll{
          width: 589px;
        }
        &.col-l{
          width: 513px;
        }
        &.col-m{
          width: 483px;
        }
        &.col-s{
          width: 430px;
        }
        &__innr{
          padding-top: 70px;
          @include media-breakpoint-down(sm){
            padding-top: 43px;
          }
        }
      }
      &__ttl{
        margin: 0 0 52px 0;
        font-size: $font-size-40;
        line-height: 1.6;
        @include media-breakpoint-down(sm){
          font-size: $font-size-28;
          margin: 0 0 34px 0;
          letter-spacing:4px;
        }
      }
      &__subttl{
        font-size: $font-size-18;
        margin-bottom: 12px;

      }
      &__text{
        line-height: 2;
        letter-spacing: 0.3rem;
        @include media-breakpoint-down(sm){
          font-size: $font-size-15;
        }
      }
      &__img{
        &.minus-right{
          @include media-breakpoint-down(sm){
            margin-right: (-$wrap-lr-space);
          }
        }
        &.minus-left{
          @include media-breakpoint-down(sm){
            margin-left: (-$wrap-lr-space);
          }
        }
      }
      .president-name{
        font-size: $font-size-24;
        letter-spacing: 0.2rem;
        line-height: 1.4;
        span{
          font-size: 67%;
        }
        @include media-breakpoint-down(sm){
          font-size: $font-size-16;
        }
      }
      @include media-breakpoint-down(sm){
        .sm-order-1{
          order: 1;
        }
        .sm-order-2{
          order: 2;
        }
      }
    }

  }
  &__products-list{
    display: flex;
    flex-flow: row wrap;
    margin: 0 -26px 113px;
    @include media-breakpoint-down(sm){
      margin: 0 0 113px;
      display: block;
    }
    &__item{
      width: calc(33% - 72px);
      margin:0 26px 67px;
      img{
        width: 100%;
        height: 193px;
        object-fit: cover;
      }
      @include media-breakpoint-down(md){
        width: calc(33% - 20px);
        margin:0 10px 67px;
        img{
          width: 100%;
          height: 160px;
          object-fit: cover;
        }
      }
      @include media-breakpoint-down(sm){
        width: 100%;
        margin:0 0 30px;
        padding: 11px;
        img{
          width: 100%;
          height: 138px;
          object-fit: cover;
        }
      }
      h3{
        font-size: $font-size-18;
        @include media-breakpoint-down(sm){
          //position: absolute;
          //bottom: 25px;
          //width: 100%;
          //margin: 0;
          //font-size: $font-size-16;
          //color: $color-bule-text;
          margin: 14px 0 8px;
        }
      }
      p{
        font-size: $font-size-14;
      }
    }
  }
  &__news-list{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 40px;
    margin-bottom: 30px;
    @include media-breakpoint-down(sm){
      display: block;
    }
    &__item{
      width: 332px;
      margin-bottom:67px;
      img{
        width: 100%;
        height: 170px;
        object-fit: cover;
      }
      @include media-breakpoint-down(md){
        width: 30%;
      }
      @include media-breakpoint-down(sm){
        width: 100%;
        margin-bottom: 30px;
        padding: 11px;
        img{
          width: 100%;
          height: 138px;
          object-fit: cover;
        }
      }
      h3{
        font-size: $font-size-18;
        @include media-breakpoint-down(sm){
          //position: absolute;
          //bottom: 25px;
          //width: 100%;
          //margin: 0;
          //font-size: $font-size-16;
          //color: $color-bule-text;
          margin: 0 0 8px;
        }
      }
      p{
        font-size: $font-size-14;
      }
    }
  }
  .company-data{
    margin: 96px 0 74px;
    @include media-breakpoint-down(sm){
      margin: 50px 0 40px;

    }
    &__list{
      display: flex;
      font-weight: 400;
      @include media-breakpoint-down(sm){
        display: block;
        margin-bottom: 30px;
      }
      &__dt{
        width: 197px;
        @include media-breakpoint-down(sm){
          margin-bottom: 6px;
        }
      }
      &__dd{

         @include media-breakpoint-down(sm){
           font-size: $font-size-14;
         }
      }
    }
  }
  .footer__wrap{
    @include media-breakpoint-down(sm){
       border-top: 1px solid $color-white;
       .footer__office__info:first-child{
         padding-top:20px;
       }
    }
  }

}


/* 個別スタイル */
.project__layer-sm-layout{
  @include media-breakpoint-down(sm){
    position:relative;
    height: 460px;
    img{
      position:absolute;
      width: 85vw;
      height: auto;
      box-shadow: 2px -2px 10px #333;
    }
    &__img1{
      top: 0;
      right: -$wrap-lr-space;
    }
    &__img2{
      bottom: 0;
      left: -$wrap-lr-space;
    }
  }
}

.list-2col{
  display: flex;
  flex-wrap: wrap;
  align-self: flex-start;
  padding: 25px 0;
  width: 100%;
  @include media-breakpoint-down(sm){
    display: block;
    padding: 0;
  }
  &__item{
    $temp_parent: &;
    width: calc(50% - 10px);
    padding: 25px 0;
    border-bottom: 1px solid $color-border;

    @include media-breakpoint-down(sm){
      width:100%;
      padding: 20px 0;
      border-bottom: 1px solid $color-border;
    }
    &:nth-child(-n+2){
      border-top: 1px solid $color-border;
    }
    &:nth-child(2n){
      @include media-breakpoint-down(sm){
        border-top: none;
      }
    }
    &:nth-child(odd){
      margin-right: 10px;
      #{$temp_parent}__innr {
        padding-right: 20px;
        border-right: 1px solid $color-border;
        margin-right: -11px;
      }
      @include media-breakpoint-down(sm){
        margin-right: 0;
        #{$temp_parent}__innr {
          padding: 0;
          border: none;
          margin-right: 0;
        }
      }
    }
    &:nth-child(even){
      margin-left: 10px;
      #{$temp_parent}__innr {
        padding-left: 20px;
        padding-right: 20px;
        position: relative;
        border-left: 1px solid $color-border;
        margin-left: -10px;
        //&:before{
        //  content: "　";
        //  border-left: 1px solid $color-border;
        //  position: absolute;
        //  left: -1%;
        //  top: 0;
        //}
      }
      @include media-breakpoint-down(sm){
        margin-left: 0;
        #{$temp_parent}__innr {
          padding: 0;
          border: none;
          margin-left: 0;
        }
      }
    }
    &__innr{

    }
    &__ttl{
      font-size: $font-size-18;
      font-weight:bold;
      letter-spacing: 0.2rem;
      background: url(../img/icon_arrow_down@2x.png) no-repeat 100% center;
      cursor: pointer;
      @include media-breakpoint-down(sm){
        font-size: $font-size-16;
      }
      span{
        padding-right: 10px;
        @include media-breakpoint-down(sm){
          font-size: 80%;
          display:block;
        }
      }
      &[aria-expanded="true"]{
        background: url(../img/icon_arrow_up@2x.png) no-repeat 100% center;
      }
    }
    &__body {
      display: none;
      margin-top: 15px;
      font-size: $font-size-14;
      letter-spacing: 0.2rem;
      line-height: 2;
      &.show{
        display: block;
      }
    }
  }

}

.products-item{
  &__list{
    &__item {
      display: flex;
      border-top: 1px solid $color-border;
      padding: 23px 0;
      &:last-child{
        border-bottom: 1px solid $color-border;
      }
      @include media-breakpoint-down(sm){
        display: block;
        border: none;
        &:last-child{
          border: none;
        }
      }
      &__th{
        margin-right: 30px;
        flex-basis: 233px;
        @include media-breakpoint-down(sm){
          margin-right: 0;
          margin-bottom: 13px;
          img{
            width: 100%!important;
            height: auto;
          }
        }
      }
      &__body{
        flex-basis: auto;
      }
      &__ttl{
        font-size: $font-size-26;
        margin-bottom: 20px;
        @include media-breakpoint-down(sm){
          border-bottom: 2px solid $color-border;
          font-size: $font-size-20;
          padding-bottom: 13px;
          margin-bottom: 0;
        }
      }
      &__text{
        margin-bottom: 20px;
        @include media-breakpoint-down(sm) {
          font-size: $font-size-14;
          padding-top: 13px;
        }
      }
      &__data{
        font-size: $font-size-14;
        display: flex;
        margin-bottom: 0;
        @include media-breakpoint-down(sm) {
          font-size: $font-size-11;
          margin-bottom: 13px;
        }
        dt{
          font-weight: normal;
          flex-basis: 80px;
        }
        dd{
          flex-basis: auto;
          margin-bottom: 3px;
          &:before{
            content: ":";
            padding-right: 8px;
          }
        }
      }
    }
  }
}

.article-box{
  h1{
    font-size: $font-size-32;
    margin: 0 0 75px;
    text-align: center;
    padding-bottom: 10px;
    border-bottom: 1px solid $color-white;
    display: inline-block;
    @include media-breakpoint-down(sm){
      font-size: $font-size-24;
      margin: 0 0 40px;
    }
  }
  h2{
    margin: 40px 0 30px;
    font-size: $font-size-20;
    border-bottom: none;
    @include media-breakpoint-down(sm){
      font-size: $font-size-18;
      margin: 30px 0 20px;
    }
  }
  h3, h4, h5, h6{
    margin: 30px 0 20px;
    font-size: $font-size-18;
    @include media-breakpoint-down(sm){
      font-size: $font-size-16;
      margin: 20px 0 15px;
    }
  }
  p{
    font-size: $font-size-14;
    line-height:1.8;
  }
  &__time{
    display:block;
    font-size: $font-size-16;
    margin-bottom: 15px;
  }
}
.project-article-box{
  margin-top: 85px;
  @include media-breakpoint-down(sm){
    margin-top: 40px;
  }
  h2{
    font-size: $font-size-32;
    margin: 0 0 65px;
    text-align: center;
    @include media-breakpoint-down(sm){
      font-size: $font-size-24;
      margin: 0 0 40px;
    }
  }
  h3{
    margin: 40px 0 30px;
    font-size: $font-size-20;
    @include media-breakpoint-down(sm){
      font-size: $font-size-18;
      margin: 30px 0 20px;
    }
  }
  h3, h4, h5, h6{
    margin: 30px 0 20px;
    font-size: $font-size-18;
    @include media-breakpoint-down(sm){
      font-size: $font-size-16;
      margin: 20px 0 15px;
    }
  }
  p{
    font-size: $font-size-14;
    line-height:1.8;
    margin-bottom: 2rem;
  }
  &__main-img{
    margin-bottom: 80px;
    text-align: center;
    img{
      max-width: 100%;
      height: auto;
    }
  }
  &__section{
    margin-bottom: 4rem;
    &__img-list{
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
      &__item{
        display: inline-block;
        width: 45%;
        img{
          max-width:100%;
          height: auto;
        }
      }
    }
  }
  &__youtube {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
  }
  &__youtube iframe {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}

.contact-form{
  background:none;
  border: 1px solid $color-white;
  padding: 10px;
  color: $color-bule-text;
  position: relative;
  &__header{
    text-align: center;
    padding: 30px;
    @include media-breakpoint-down(sm){
      padding: 56px 0 37px;
    }
  }
  &__ttl{
    display: inline-block;
    font-size: $font-size-30;
    font-family: $font-family-montserrat;
    color: $color-white;
    border-bottom: 2px solid $color-white;
    letter-spacing: 10px;
    font-weight: $font-weight-bold;
    padding-bottom: 10px;
    @include media-breakpoint-down(sm){
      font-size: $font-size-26;
    }
  }
  &__innr{
    width: 780px;
    padding: 42px 22px;
    background: $color-white;
    @include media-breakpoint-down(sm){
      width: 88vw;
      padding: 29px 10px;
    }

  }
  &__dl{
    display: flex;
    flex-wrap: wrap;
    font-size: $font-size-12;
    padding: 0 40px;
    @include media-breakpoint-down(sm){
      padding: 0;
      margin-bottom: 0;
    }
    dt{
      width: 20%;
      margin-bottom: 20px;
      padding: 5px 0;
      @include media-breakpoint-down(sm){
        margin-bottom: 0px;
        width: 100%;
      }
    }
    dd{
      width: 80%;
      margin-bottom: 20px;
      @include media-breakpoint-down(sm){
        width: 100%;
      }
    }
    input,textarea{
      width: 100%;
      border: 1px solid $color-line-gray;
      border-radius: 3px;
      padding: 5px 8px;

    }
    input{

    }
    textarea{
      height: 190px;
    }
  }
  &__submit{
    text-align: center;
    input, button{
      width: 40%;
      margin: 0;
      @include media-breakpoint-down(sm){
        width: 100%;
      }
    }
  }
  &__hr{
    border:none;
    border-top: 2px solid #D9D9D9;
    margin: 30px 0;

  }
  &__footer{
    text-align: center;
    p{
      font-size: $font-size-12;
      margin-bottom: 20px;
      font-weight: bold;
    }
    &__list{
      &__item{
        display: inline-block;
        padding: 0 0 0 40px;
        line-height: 28px;
        height: 28px;
        letter-spacing:2px;
        font-weight: bold;
        @include media-breakpoint-down(sm){
          text-align: left;
          width: 90%;
          margin-bottom: 20px;
        }
        &.tel{
          background: url(../img/icon_phone.png) no-repeat left center;
        }
        &.mail{
          background: url(../img/icon_letter.png) no-repeat left center;
          margin-left: 40px;
          @include media-breakpoint-down(sm){
            margin-left: 0;
          }
        }
      }
    }
  }
  &__btn-close{
    cursor: pointer;
    border-left: 1px solid $color-white;
    border-bottom: 1px solid $color-white;
    padding: 13px;
    position:absolute;
    top: 0;
    right: 0;
  }
  &__btn-close-sp{
    display: none;

    @include media-breakpoint-down(sm){
      display: block;
    }
  }

  /* contactform7 */
  .wpcf7{
    form{
      .wpcf7-form-control-wrap{
        display: block;
        width: 100%;
      }
      .wpcf7-not-valid-tip{
        padding-top: 5px;
        font-size: 80%;
      }
      .wpcf7-response-output {
        text-align: center;
        padding: 10px;
        font-size: 80%;
      }
      &.invalid{
        .wpcf7-response-output {
          border-color: #dc3232;
          color: #dc3232;
        }
      }
      &.send{
        .wpcf7-response-output {
          border-color: #46b450;
          color: #46b450;
        }
      }
    }
    .ajax-loader{
      display: none;
      //display: block;
      //text-align: center;
      //&:before {
      //  left: 50%;
      //  top: 50%;
      //}
    }
  }
}

.wp-editor-area{
  p {
    display: block;
    margin: 1em 0;
  }
  strong { font-weight: bold; }
  em { font-style: italic; }
  blockquote {
    display: block;
    -webkit-margin-before: 1em;
    -webkit-margin-after: 1em;
    -webkit-margin-start: 40px;
    -webkit-margin-end: 40px;
  }

  /* img */

  .aligncenter {
    display: block;
    margin: 0 auto;
  }
  .alignright {
    float: right;
    margin: 0 0 10px 15px;
  }
  .alignleft {
    float: left;
    margin: 0 0px 10px 0;
  }

  img[class*="wp-image-"],
  img[class*="attachment-"] {
    height: auto;
    max-width: 100%;
  }

  /* clearfix */

  .clearfix {
    overflow: hidden;
    zoom: 1;
  }
  .clearfix:after {
    content: "";
    display: block;
    clear: both;
  }

}

.award-list{
  display: flex;
  justify-content: space-between;
  @include media-breakpoint-down(sm) {
    display: block;
  }
  &__col{
    width: 49%;
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
    &__list{
      &__item{
        $temp_parent: &;
        padding: 25px 0;
        border-bottom: 1px solid $color-border;
        &:first-child{
          border-top: 1px solid $color-border;
        }
        &__sm{
          @include media-breakpoint-down(sm) {
            border-top: none !important;
          }
        }
        &__innr{
          padding-right: 20px;
          @include media-breakpoint-down(sm){
            padding-right: 0;
          }
        }
        &__ttl{
          font-size: $font-size-18;
          font-weight:bold;
          letter-spacing: 0.2rem;
          background: url(../img/icon_arrow_down@2x.png) no-repeat 100% center;
          cursor: pointer;
          @include media-breakpoint-down(sm){
            font-size: $font-size-16;
            padding-right: 35px;

          }
          span{
            padding-right: 10px;
            @include media-breakpoint-down(sm){
              font-size: 80%;
              display:block;
            }
          }
          &[aria-expanded="true"]{
            background: url(../img/icon_arrow_up@2x.png) no-repeat 100% center;
          }
        }
        &__body {

          margin-top: 15px;
          font-size: $font-size-14;
          letter-spacing: 0.2rem;
          line-height: 2;

        }
        .collapsing {
          -webkit-transition: height 0.35s ease;
          transition: height 0.35s ease;
        }
      }
    }
  }
}
