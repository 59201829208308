//
// ブレイクポイント
//
//$pc: 992px; // PC
$tab: 991px; // タブレット
$sp: 576px;  // スマホ


$w_design_sp: 375px;

//画像フォルダ
$img_path: "/img/";

//カラー設定
$color-white: #ffffff;
$color-black: #000000;
$color-border: #b3c2cf;

$color-main-bg: #130421;
$color-main-text: #FFFFFF;
$color-bule-text:  $color-main-bg;
$color-purple-text: #1E092C;
$color-btn-hover: #28295D;
$color-line-gray: #CBCBCB;

$max-contents-width: 1100px;

//コンテンツを100%にした場合の両サイド余白
$wrap-lr-space: 20px;

$font-family-main: "游ゴシック体", "YuGothic", "游ゴシック", "Yu Gothic", "メイリオ", "Hiragino Sans", sans-serif;
$font-family-montserrat: 'Montserrat', sans-serif;


//16px=1em;;
$font-size-8: 0.50em;
$font-size-9: 0.56em;
$font-size-10: 0.63em;
$font-size-11: 0.69em;
$font-size-12: 0.75em;
$font-size-13: 0.81em;
$font-size-14: 0.88em;
$font-size-15: 0.94em;
$font-size-16: 1.00em;
$font-size-17: 1.06em;
$font-size-18: 1.13em;
$font-size-19: 1.19em;
$font-size-20: 1.25em;
$font-size-21: 1.31em;
$font-size-22: 1.38em;
$font-size-23: 1.44em;
$font-size-24: 1.50em;
$font-size-25: 1.56em;
$font-size-26: 1.63em;
$font-size-27: 1.69em;
$font-size-28: 1.75em;
$font-size-29: 1.81em;
$font-size-30: 1.88em;
$font-size-31: 1.94em;
$font-size-32: 2.00em;
$font-size-33: 2.06em;
$font-size-36: 2.26em;
$font-size-40: 2.50em;
$font-size-50: 3.12em;
$font-size-60: 3.76em;
$font-size-80: 5.0em;
